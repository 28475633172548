<template>
  <PageEntry
    :certifications="certificationsSorted"
    :featured-articles="featuredArticles"
    :header-content="headerContent"
    :most-sold-products="mostSoldProducts"/>
</template>

<script>
import AppRouteMixin from '~/mixins/AppRouteMixin'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import PageEntry from '@theme/components/content/PageEntry'
import SeoMixin from '~/mixins/SeoMixin'

const PAGE = {
  name: 'RouteHomepage',
  nuxtI18n: {
    paths: {
      cs: '/',
      en: '/',
      sk: '/',
    },
  },
  components: {
    PageEntry,
  },
  mixins: [AppRouteMixin, ImageUrlMixin, SeoMixin],
  head() {
    const meta = [
      { hid: 'description', name: 'description', content: this.$store.state.globals.meta.seo_description },
      { hid: 'keywords', name: 'keywords', content: this.$store.state.globals.meta.seo_keywords },
      { property: 'og:description', content: this.$store.state.globals.meta.seo_description },
      {
        property: 'og:image',
        content: this.imageUrl({
          src: this.$store.state.globals.meta.seo_image,
        }),
      },
      { property: 'og:site_name', content: this.$store.state.globals.meta.seo_title },
      { property: 'og:title', content: this.$store.state.globals.meta.seo_title },
      ...this.robotsMetaTag(),
    ]
    if (this.$store.state.globals.settings.integrations.google_site_verification) {
      meta.push({
        hid: 'google-site-verification',
        name: 'google-site-verification',
        content: this.$store.state.globals.settings.integrations.google_site_verification,
      })
    }
    return {
      title: this.$store.state.globals.meta.seo_title,
      meta,
      link: [...this.alternateLinks()],
    }
  },
  computed: {
    certificationsSorted() {
      const certificationsWithImage = this.certifications.filter(
        c => c.parameter_value_image && c.parameter_value_image.length > 0,
      )
      return certificationsWithImage && certificationsWithImage.length > 0
        ? this.sortByWeight(certificationsWithImage)
        : null
    },
    featuredArticles() {
      return this.articles && this.articles.entities
        ? this.articles.entities.filter(a => a.image !== null && a.image.length > 0)
        : null
    },
  },
  methods: {
    sortByWeight(data) {
      if (Array.isArray(data)) {
        data.sort((a, b) => {
          return a.weight - b.weight
        })
      }
      return data
    },
  },
  async asyncData({ store, res, app }) {
    if (process.server) {
      res.set('X-Inspishop-Cache-Control', `public, max-age=${app.$env.STATELESS_TTL}`)
    }

    store.commit('locale/SET_CANONICALS', {
      nuxtI18nPaths: PAGE.nuxtI18n.paths,
    })

    const data = {
      certifications: store.dispatch('certification/LOAD_CERTIFICATIONS'),
      headerContent: store.dispatch('entry/LOAD_HEADER_CONFIGURATION'),
      mostSoldProducts: store.dispatch('product/LOAD_FEATURED_PRODUCTS', { type: 'most_sold', size: 8 }),
      articles: store.dispatch('article/LOAD_ARTICLES', {
        page: 0,
        perPage: app.$themeSettings.components.PageHomepage.featuredArticles.count,
      }),
    }
    return await Promise.allValues(data)
  },
}
export default PAGE
</script>
