import { hydrateWhenVisible } from 'vue-lazy-hydration'
import { map } from 'lodash'
import AppImage from '@theme/components/atom/AppImage'
import AppLink from '@theme/components/atom/AppLink'
import AppTitle from '@theme/components/atom/AppTitle'
import Carousel from '@theme/components/utils/Carousel'
import CookieConsent from '@theme/components/shop/CookieConsent'
import FeaturedArticles from '@theme/components/content/FeaturedArticles'
import FooterBottom from '@theme/components/shop/FooterBottom'
import FooterMain from '@theme/components/shop/FooterMain'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import Logo from '@theme/components/molecule/Logo'
import Newsletter from '@theme/components/shop/Newsletter'
import ProductBox from '@theme/components/product/ProductBox'
import Story from '@theme/components/content/Story/StoryLazy'

export default {
  mixins: [ImageUrlMixin],
  props: {
    certifications: Array,
    featuredArticles: Array,
    headerContent: Object,
    mostSoldProducts: Array,
  },
  components: {
    AppImage,
    AppLink,
    AppTitle,
    Carousel: hydrateWhenVisible(Carousel),
    CookieConsent,
    FeaturedArticles: hydrateWhenVisible(FeaturedArticles),
    FooterBottom: hydrateWhenVisible(FooterBottom),
    FooterMain: hydrateWhenVisible(FooterMain),
    Logo,
    Newsletter: hydrateWhenVisible(Newsletter),
    ProductBox,
    Story: hydrateWhenVisible(Story),
  },
  data() {
    return {
      headerImage: null,
      EntryMenuMobileComponent: null,
      EntryMenuMobileAriaControls: null,
      open: false,
      topContentItems:
        typeof this.$t('PageEntry.top.content.items') === 'object' ? this.$t('PageEntry.top.content.items') : null,
      topContentIcons: [
        require(`@icon/quality.svg?raw`),
        require(`@icon/innovation.svg?raw`),
        require(`@icon/fair-play.svg?raw`),
        require(`@icon/sustainability.svg?raw`),
        require(`@icon/purity.svg?raw`),
      ],
    }
  },
  mounted() {
    this.headerImage = this.imageUrl({ src: this.headerContent.entry_header_image[0].path })
  },
  methods: {
    async toggleMobileMenu() {
      const toggle = () => {
        this.$refs.EntryMenuMobile.toggleMenu()
        this.open = !this.open
        this.$emit('changeOpen', this.open)
        this.EntryMenuMobileAriaControls = 'EntryMenuMobile'
      }
      if (!this.EntryMenuMobileComponent) {
        this.EntryMenuMobileComponent = (await import('@theme/components/shop/MainMenuMobile')).default
        this.$nextTick(() => {
          toggle()
        })
      } else {
        toggle()
      }
    },
  },
}
