<script>
import LazyComponentMixin from '~/mixins/LazyComponentMixin'

export default {
  mixins: [LazyComponentMixin],
  data() {
    return {
      wrapperClass: 'StoryLazy',
      componentFactory: () => import('./Story.vue'),
    }
  },
}
</script>

<style lang="scss">
@import '~variables';

.StoryLazy > .PlaceholderBox {
  min-height: 406px;

  @include media-breakpoint-up(lg) {
    min-height: 406px;
  }
}
</style>
